import { Component, computed, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AvatarComponent,
  ColorModeService,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  NavItemComponent,
  NavLinkDirective,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective
} from '@coreui/angular';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive, NavigationEnd, Router } from '@angular/router';
import { IconDirective } from '@coreui/icons-angular';

import { SharedService } from '../../../../services/shared.service';
import { filter, Subscription } from 'rxjs';
import { DeploymentsService } from '../../../../../shared/services/deployments.service';
import { ProjectsService } from '../../../../../pages/projects/projects.service';
import { ListItem } from '../../../../../core/models/list-item.model';
import { AuthService } from '../../../../../core/services/auth.service';
import { CasdoorService } from '../../../../../core/services/casdoor.service';
import { ModalComponent } from '../../../model/model.component';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
  standalone: true,
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, AvatarComponent,
    IconDirective, HeaderNavComponent, NavItemComponent, NavLinkDirective, RouterLink,
    RouterLinkActive, NgTemplateOutlet, ThemeDirective,
    DropdownComponent, DropdownToggleDirective, TextColorDirective,
    DropdownMenuDirective, DropdownHeaderDirective, DropdownItemDirective, DropdownDividerDirective, CommonModule , ModalComponent],
  providers: [DeploymentsService, ProjectsService ]
})

export class DefaultHeaderComponent extends HeaderComponent implements OnInit, OnDestroy {

  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;

  readonly colorModes = [
    { name: 'light', text: 'Light', icon: 'cilSun' },
    { name: 'dark', text: 'Dark', icon: 'cilMoon' }
  ];

  readonly icons = computed(() => {
    const currentMode = this.colorMode();
    return this.colorModes.find(mode => mode.name === currentMode)?.icon ?? 'cilSun';
  });

  public showEnvironmentConfig: any;
  private projectSubscription: Subscription | undefined;
  private envSubscription: Subscription | undefined;
  listOfEnviroinments: ListItem[] = [];
  listOfProjects: ListItem[] = [];
  listOfRegions: ListItem[] = [];
  selectedEnvironment: string = '';
  selectedProject: string = '';
  selectedRegion: string = '';
  projectId:string = '';
  routeName: any =
    localStorage.getItem('routeName') === "Login Page" ||
      localStorage.getItem('routeName') === "Environment Page"
      ? 'Deployments'
      : localStorage.getItem('routeName');

  constructor(private authService: AuthService,
    private sharedService: SharedService, private http: DeploymentsService, private activatedRout: ActivatedRoute, private route: Router,
    private projectService: ProjectsService, private casdoorService: CasdoorService,
  ) {
    super();

  }

  @Input() sidebarId: string = 'sidebar1';
  @ViewChild('showEnvironmentModel') private showEnvironmentModel!: ModalComponent;

  ngOnInit(): void {
    if (window.location.href.indexOf('code') !== -1) {
      this.activatedRout.queryParams.subscribe(code => {
        if (!localStorage.getItem('casdoor_token') || localStorage.getItem('casdoor_token') == undefined) {
          this.casdoorService.getCasdoorToken(code?.['code']).subscribe((res: any) => {
            if (res.status == 'success') {
              this.authService.setAccessToken(res.data);
              // sessionStorage.setItem('token', res.data);
              this.getBasicInfo();
            } else {
              this.authService.logout();
            }
            // this.setTokenReceived(true);

            // this.getInfo().then((res) => this.setInfo(res));
          });
        }else{
         
          this.getBasicInfo();
        }
      })

    } else {
      if (localStorage.getItem('casdoor_token') || localStorage.getItem('casdoor_token') != undefined) {
        // this.getInfo().then((res) => this.setInfo(res));
        this.getBasicInfo();
      } else {
        window.location.href = this.casdoorService.getLoginUrl();
      }

    }

    this.projectSubscription = this.sharedService.projectDDChange$.subscribe((projects: any[]) => {
      if (projects && projects.length > 0) {
      this.listOfProjects = projects;
      this.getSelectedProject(this.listOfProjects[0]);
      }
      else
      {
        this.listOfProjects = [];
        this.selectedProject = '';
      }
    });

     this.envSubscription = this.sharedService.envDDChange$.subscribe((env: any[]) => {
      if (env && env.length > 0) {
       this.listOfEnviroinments = env;
       this.getSelectedEnv(this.listOfEnviroinments[0]);
      }
      else
      {
        this.listOfEnviroinments = [];
        this.selectedEnvironment = '';
      }
     });

     this.showEnvironmentConfig = {
      modalTitle: 'Switch project and environment',
      width: '800px',
      height: '200px',
      hideDismissButton: () => true,
      hideCloseButton: () => true
    };
  }

  private getEnvironment() {
    if (!this.projectId) return;
    this.projectService.getEnvironmentsByProject(this.projectId).subscribe((res: any) => {
      if (res?.data && res.data.length > 0) {
        this.getSelectedEnv(res.data[0]);
        this.listOfEnviroinments = res?.data;
        this.selectedEnvironment = res.data[0]?.name;
      } else {
        this.selectedEnvironment = '';
        this.listOfEnviroinments = [];
      }
    },
    (err) => {
      this.selectedEnvironment = '';
        this.listOfEnviroinments = [];
    });
  }

  private getRouteName(route: ActivatedRoute): string {
    if (route.firstChild) {
      return this.getRouteName(route.firstChild);
    }
    if (Object.keys(route.snapshot.queryParams).length == 0 && route.snapshot.component?.name.toLowerCase().includes('create'))
      return 'Create ' + route.snapshot.data['title'];
    else
      return route.snapshot.data['title'];
  }

  setTheme(color: any) {
    this.sharedService.setCookie('theme', color, 10)
    this.colorMode.set(color);
    this.sharedService.emitValueChange(color);
    this.#colorModeService.setStoredTheme('selectedTheme', color);
  }
  getSelectedEnv(env: any) {
    if (env) {
    this.sharedService.setCookie('environment', JSON.stringify(env), 10);
    this.selectedEnvironment = env?.name;
    console.log(this.selectedEnvironment);
    this.sharedService.emitEnvValueChange(env);
    } 
  }

  getSelectedRegion(region: any){
    if(region){
    this.sharedService.setCookie('region', JSON.stringify(region), 10);
    this.selectedRegion = region?.name;
    this.selectedEnvironment = region.environments[0]?.name;
    this.listOfEnviroinments = region.environments;
    }
  }
  
  getSelectedProject(project: any) {
    if (project) {
    this.sharedService.setCookie('project', JSON.stringify(project), 10);
    this.sharedService.emitProjectValueChange(project);  // Notify other components
    this.selectedProject = project?.name;
    this.projectId = project?.id;
    this.getEnvironment(); 
    console.log(this.selectedProject);
    this.getRegionsAndEnvironment();  // Load environment list for selected project
    }
  }

  logout() {
    this.authService.logout();
  }
  isSelectedEnv(item: any): boolean {
    return this.selectedEnvironment === item.name;
  }
  isSelectedRegion(item: any): boolean {
    return this.selectedRegion === item.name;
  }
  isSelectedProject(item: any): boolean {
    return this.selectedProject === item.name;
  }

  public getRegionsAndEnvironment() {
    if (!this.projectId) return;
  
    this.projectService.getProjectById(this.projectId, true).subscribe((res: any) => {
      if (res?.data) {
        if(res.data.regions?.length > 0){
          this.listOfRegions = res.data.regions;
          this.selectedRegion = res.data.regions[0]?.name;
          if(res.data.regions[0]?.environments?.length > 0){
            this.getSelectedRegion(res.data.regions[0]);
          }
        }
      } else {
        this.selectedRegion = '';
        this.listOfRegions = [];
        this.selectedEnvironment = '';
        this.listOfEnviroinments = [];
      }
    },
    (err) => {
      this.selectedRegion = '';
        this.listOfRegions = [];
      this.selectedEnvironment = '';
        this.listOfEnviroinments = [];
    });
  }
  
  private getBasicInfo() {
    const project = this.sharedService.getCookie('project');
    this.projectService.getAllProjects().subscribe((res: any) => {
      this.listOfProjects = res?.data;
      if (project && this.listOfProjects.find(item => item.name === JSON.parse(project)?.name) !== undefined) {
        this.selectedProject = JSON.parse(project)?.name;
        this.projectId = JSON.parse(project)?.id;
        this.getRegionsAndEnvironment();
      } else {
        this.getSelectedProject(res?.data[0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.projectSubscription?.unsubscribe();
    this.envSubscription?.unsubscribe();
  }

  showEnvironment(){
     this.showEnvironmentModel.open();
  }
}
