import { Component, computed, HostListener, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import { IconDirective } from '@coreui/icons-angular';
import {
  ColorModeService,
  ContainerComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular';

import { navItems } from './_nav';
import { CommonModule, NgIf } from '@angular/common';
import { DefaultFooterComponent } from './default-footer/default-footer.component';
import { DefaultHeaderComponent } from './default-header/default-header.component';
import { AuthService } from '../../../../core/services/auth.service';
import { DeploymentsService } from '../../../services/deployments.service';
import { BreadCrumbComponent } from '../../bread-crumb/bread-crumb.component';
import { pageHeaders } from '../../../../core/constants/resource';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { SharedService } from '../../../services/shared.service';

// function isOverflown(element: HTMLElement) {
//   return (
//     element.scrollHeight > element.clientHeight ||
//     element.scrollWidth > element.clientWidth
//   );
// }

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    IconDirective,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent, NgIf, CommonModule,
    BreadCrumbComponent
  ],
  providers: [DeploymentsService]
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;
  isScrolled = false;
  userInfo: any;
  pageHeaders = pageHeaders;
  pageTitle: string = 'Dashboard';
  subText: string = 'Dashboard';

  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;


  constructor(private authService: AuthService, private deployemntService: DeploymentsService,
    private router: Router, private titleService: Title, private ac: ActivatedRoute, private sharedService: SharedService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateTitle();
    });
    
  }

  @HostListener('window:scroll', [])
  onScroll() {
    this.isScrolled = window.scrollY > 50;
  }

  onScrollbarUpdate($event: any) {
    console.log($event.verticalUsed)

  }

  ngOnInit(): void {
    this.deployemntService.getAccountInfo().subscribe((res: any) => {
      this.userInfo = res.data;
      localStorage.setItem('userId', res.data?.id)
      console.log(res)
    })
  }

  updateTitle(): void {
    const currentUrl = this.router.url.split('?')[0];
    const matchedTitle = this.pageHeaders.find((title: any) => title.url === currentUrl);
    if (matchedTitle) {
      this.titleService.setTitle(matchedTitle.title);
      this.pageTitle = matchedTitle.title;
      this.subText = matchedTitle.subText
    } else {
      this.titleService.setTitle('Dashboard');
      this.pageTitle = 'Dashboard';
    }
  }
  setTheme(event: Event) {
    const color = (event.target as HTMLInputElement).checked ? 'light' : 'dark';
    this.sharedService.setCookie('theme', color, 10)
    this.colorMode.set(color);
    this.sharedService.emitValueChange(color);
    this.#colorModeService.setStoredTheme('selectedTheme', color);
  }

  logout() {
    this.authService.logout();
  }
}
