<ng-container>
  <c-container [fluid]="true" class="px-4">
    <!-- <button [cSidebarToggle]="sidebarId" cHeaderToggler class="btn side-bar-toggler rounded-circle" toggle="visible"
      style="margin-inline-start: -14px;">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav class="d-none d-md-flex m-0 align-items-center">
    </button> -->
    <c-header-nav class="d-none d-md-flex">
      <!-- <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
        <h4 class="mb-0">{{routeName}}</h4> 
      </c-nav-item> -->
        <ul class="list-unstyled d-flex gap-2 pt-0 m-0 align-items-center">
        <li><a class="text-decoration-none text-body"> {{ selectedProject }} </a></li>
        <li>
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li>
        <li><a class="text-decoration-none text-body"> {{ selectedEnvironment }} </a></li>
        <li class="opacity-75"> - </li>
        <li class="change-env"><a class=" text-decoration-none text-body" (click)="showEnvironment()"> change </a></li>
        </ul>
    </c-header-nav>

    <c-header-nav class="d-none d-md-flex ms-auto">
      <ng-container>
        <c-dropdown class="mx-2">
          <!-- [style.background]="colorMode() === 'dark' ? '#212631' : '#dee2e6'" -->
          <button cButton cDropdownToggle class="btn btn-drop-down">
            {{selectedProject}}
          </button>
          <ul cDropdownMenu>
            <li *ngFor="let list of listOfProjects" [class.active]="isSelectedProject(list)"
              (click)="getSelectedProject(list)"><a [routerLink]="[]" cDropdownItem>{{list?.name}}</a></li>
          </ul>
        </c-dropdown>
        <div class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </div>
      </ng-container>
    </c-header-nav>
    <c-header-nav>
      <ng-container>
        <c-dropdown>
          <button cButton cDropdownToggle class="btn btn-drop-down">
            {{selectedRegion}}
          </button>
          <ul cDropdownMenu>
            <li *ngFor="let list of listOfRegions" [class.active]="isSelectedRegion(list)"
            (click)="getSelectedRegion(list)"
              ><a [routerLink]="[]" cDropdownItem>{{list?.name}}</a></li>
          </ul>
        </c-dropdown>
        <div class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </div>
      </ng-container>
    </c-header-nav>
    <c-header-nav>
      <ng-container>
        <c-dropdown>
          <!-- [style.background]="colorMode() === 'dark' ? '#212631' : '#dee2e6'" -->
          <button cButton cDropdownToggle class="btn btn-drop-down">
            {{selectedEnvironment}}
          </button>
          <ul cDropdownMenu>
            <li><a [routerLink]="['/environment']" cDropdownItem class="b-0"> + New Environment</a></li>
            <li *ngFor="let list of listOfEnviroinments" [class.active]="isSelectedEnv(list)"
              (click)="getSelectedEnv(list)"><a [routerLink]="[]" cDropdownItem>{{list?.name}}</a></li>
            
          </ul>
        </c-dropdown>
        <div class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </div>
      </ng-container>
    </c-header-nav>
    <!-- <c-header-nav class="ms-auto ms-md-0">
      
      <ng-container *ngTemplateOutlet="themeDropdown" />
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
    </c-header-nav> -->

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>

  </c-container>
</ng-container>

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0 pe-0">
      <c-avatar shape="rounded-1" [size]="'s'" src="./assets/images/avatars/avatar.jpg" textColor="primary" />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li></li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/account-settings">
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Account Settings
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="" (click)="logout()">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<!-- <ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      <svg cIcon [name]="icons()" size="lg"></svg>
    </button>
    <div cDropdownMenu>
      @for (mode of colorModes; track mode.name) {
      <button (click)="setTheme(mode.name)" [active]="colorMode()===mode.name" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" [name]="mode.icon" size="lg"></svg>
        {{ mode.text }}
      </button>
      }
    </div>
  </c-dropdown>
</ng-template> -->

<app-modal  #showEnvironmentModel [modalConfig]="showEnvironmentConfig">
  <div class="container">
    <h6 class="section-info text-secondary mb-3 fw-medium">Please choose you projecct and environment based on your preference</h6>
    <div class="d-flex p-3 rounded-3" style="max-height: 500px; overflow: hidden">

    <div class="pe-3 border-end flex-shrink-0" style="width: 250px; overflow-y: auto;">
      <h5 class="section-title mt-2 mb-4">Projects</h5>
      <ul class="projects-list p-0 list-unstyled overflow-auto">
        <li 
          *ngFor="let project of listOfProjects" 
          (click)="getSelectedProject(project)" 
          [class.active]="isSelectedProject(project)"
          class="project-item fw-medium  p-2 mb-1 rounded-2">
          {{ project.name }}
        </li>
      </ul>
    </div>

    <div class="environments-section">
      <h4 class="section-title mt-2 mb-4">Environments</h4>
      <div class="environments-grid">
        <div 
          *ngFor="let environment of listOfEnviroinments" 
          (click)="getSelectedEnv(environment)" 
          [class.active]="isSelectedEnv(environment)"
          class="environment-card rounded-4 p-2">
            <p class="env-name fs-4">{{ environment.name }}</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</app-modal>