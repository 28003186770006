import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'bi bi-speedometer'
  },
  {
    name: 'Deployments',
    url: '/deployment',
    icon: 'bi bi-cloud-upload'
  },
  {
    name: 'Tools',
    url: '/tools',
    icon: 'bi bi-tools'
  },
  {
    name: 'Endpoints',
    url: '/endpoints',
    icon: 'bi bi-diagram-3'
  },
  // {
  //   name: 'Configs',
  //   url: '/configs',
  //   icon: 'bi bi-wrench'
  // },
  // {
  //   name: 'Secrets',
  //   url: '/secrets',
  //   icon: 'bi bi-lock'
  // },
  // {
  //   name: 'Volumes',
  //   url: '/volumes',
  //   icon: 'bi bi-hdd'
  // },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'bi bi-gear'
  }
]
